<script setup>
import {computed, ref} from "vue";
import Menu from 'primevue/menu'

const props = defineProps({
    /**
     * Items to display in menus. Key is the menu item id. Exemple :
     * {
     *     about: {
     *         text: 'À propos',
     *         icon: 'mdi mdi-information-outline',
     *         to: '/about',
     *     },
     * }
     */
    items: {
        type: Object,
        required: true,
        default: () => ({}),
    },

    /**
     * Menu items order. The list of menu items keys in proper order. Those no included won't be displayed.
     */
    itemsOrder: {
        type: Array,
        default: () => [],
    },
    /**
     * Maximum menus items visible, others will be in the plus menu.
     */
    maxVisible: {
        type: Object,
        default: () => ({
            'sm': 4,
            'md': 4,
            'lg': 6,
            'xl': 7,
        }),
    }
})

const plusMenu = ref(false)

const itemsList = computed(() => (props.itemsOrder && props.itemsOrder.length ? props.itemsOrder : Object.keys(props.items)))

const plusMenuItems = computed(() => {

    let items = [];
    itemsList.value.forEach((item, index) => {
        if(undefined !== props.maxVisible.sm && index+1 < props.maxVisible.sm ) {
            return
        }

        let itemClass = ''

        if(undefined !== props.maxVisible.xl && index+1 < props.maxVisible.xl ) {
            itemClass = 'xl:hidden'
        }
        if(undefined !== props.maxVisible.lg && index+1 < props.maxVisible.lg ) {
            itemClass = 'lg:hidden'
        }
        if(undefined !== props.maxVisible.md && index+1 < props.maxVisible.md ) {
            itemClass = 'md:hidden'
        }

        items.push( {
            ...props.items[item],
            class: itemClass,
        })
    })

    return items

})

const itemClasses ='flex-1 text-center md:flex-grow-0 md:mb-4 md:mt-3'

const itemClass = (index) => {
    const i = parseInt(index) + 1;
    let classes = itemClasses

    // less than less, nothing to ever hide
    if (undefined !== props.maxVisible.sm && (i < props.maxVisible.sm ||
        (i === props.maxVisible.sm && itemsList.value.length === props.maxVisible.sm))) {
        return classes
    }

    // more than the most, always hidden
    if (undefined !== props.maxVisible.xl
        && (i > props.maxVisible.xl
            || (i === props.maxVisible.xl && itemsList.value.length > props.maxVisible.xl))) {
        classes += ' hidden'
    } else if (undefined !== props.maxVisible.lg
        && (i > props.maxVisible.lg
            || (i === props.maxVisible.lg && itemsList.value.length > props.maxVisible.lg))) {
        classes += ' hidden xl:block'
    } else if (undefined !== props.maxVisible.md
        && (i > props.maxVisible.md
            || (i === props.maxVisible.md && itemsList.value.length > props.maxVisible.md))) {
        classes += ' hidden lg:block'
    } else if (undefined !== props.maxVisible.sm
        && (i > props.maxVisible.sm
            || (i === props.maxVisible.sm && itemsList.value.length > props.maxVisible.sm))) {
        classes += ' hidden md:block'
    }
    return classes
}

const itemDisabled = (item) => {
    if(typeof item.disabled !== 'undefined') {
        if (typeof item.disabled === 'function') {
            return item.disabled()
        }
        return !!item.disabled
    }
    return false
}
const plusItemClass = () => {
    let classes = itemClasses

    if (itemsList.value.length <= props.maxVisible.sm) {
        classes += ' hidden'
    } else if (itemsList.value.length <= props.maxVisible.md) {
        classes += ' md:hidden'
    } else if (itemsList.value.length <= props.maxVisible.lg) {
        classes += ' lg:hidden'
    } else if (itemsList.value.length <= props.maxVisible.xl) {
        classes += ' xl:hidden'
    }
    return classes
}

const plusMenuToggle = (event) => {
    plusMenu.value.toggle(event);
};
</script>
<template>
    <nav
        class="flex bottom-navigation justify-content-between pt-1 md:flex-column md:h-full md:justify-content-start max-h-full md:pr-2 md:pl-2 overflow-y-auto overflow-x-hidden">
        <div v-for="(item, index) in itemsList" :key="item" :class="itemClass(index)">
            <router-link :to="items[item].to" v-if="! itemDisabled(items[item])"><div class="flex align-items-center"><i class="mdi mdi-chevron-left text-2xl -ml-2 -mr-1" v-if="items[item].back" /><div><span><i :class="items[item].icon" class="text-3xl -mb-2 md:mb-0"/></span><span
                class="text-xs">{{ items[item].label }}</span></div></div></router-link>
            <span class="link-disabled" v-else><div class="flex align-items-center"><i class="mdi mdi-chevron-left text-2xl -ml-2 -mr-1" v-if="items[item].back" /><div><span><i :class="items[item].icon" class="text-3xl -mb-2 md:mb-0"/></span><span
                class="text-xs">{{ items[item].label }}</span></div></div></span>
        </div>
        <div :class="plusItemClass()">
            <a href="" @click.prevent="plusMenuToggle" aria-haspopup="true" aria-controls="plus_menu"><i class="mdi mdi-dots-horizontal text-3xl -mb-2 md:mb-0"/><span class="text-xs">Plus</span></a>
        </div>
        <Menu ref="plusMenu" :model="plusMenuItems" id="plus_menu" :popup="true"></Menu>
    </nav>
</template>
