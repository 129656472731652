import {inject, ref} from "vue";

import {useDialog} from "primevue/usedialog";
import {dialogsSkills} from "./dialogs/skills";
import {dialogsUsers} from "./dialogs/users";
import {dialogsTrainees} from "@/composables/dialogs/trainees";
import {dialogsOrganizations} from "@/composables/dialogs/organizations";

import TraineeIndicatorValidation from "@/components/Trainee/IndicatorValidation.vue"

export function useDialogs() {

    const dynamicDialogDefaults = inject('dynamicDialogDefaults')
    const dialog = useDialog()
    const $dialogViews = ref({
        dialogUserView: null,
        dialogUserEdit: null,
        dialogSkills: null,
        dialogIndicatorValidation: null,
        dialogTraineeEdit: null,
        dialogTraineeActivate: null,
        dialogTraineeResendEmailActivation: null,
        dialogOrganizationView: null,
        dialogOrganizationEdit: null,
    })

    const {skillsView} = dialogsSkills(dynamicDialogDefaults)
    const {userView, userEdit, userAccessTokenView, userAccessTokenQRScan} = dialogsUsers(dynamicDialogDefaults)
    const {
        traineeEdit,
        traineeActivate,
        traineeResendEmailActivation,
        traineeAccessTokenView,
        traineeJobForm,
        traineeQualificationForm
    } = dialogsTrainees(dynamicDialogDefaults)

    const {organizationView, organizationEdit} = dialogsOrganizations(dynamicDialogDefaults)

    // skills
    const dialogSkills = (skill, trainee, params) => {
        return $dialogViews.value.dialogSkills = skillsView(skill, trainee, params)
    }

    // users

    const dialogUserView = (item, organization, params) => {
        return $dialogViews.value.dialogUserView = userView(item, organization, params)
    }
    const dialogUserEdit = (item, organization) => {
        return $dialogViews.value.dialogUserEdit = userEdit(item, organization)
    }

    const dialogUserAccessTokenView = (params) => {
        return $dialogViews.value.dialogAccessTokenView = userAccessTokenView(params)
    }
    const dialogUserAccessTokenQRScan = (params) => {
        return $dialogViews.value.dialogAccessTokenView = userAccessTokenQRScan(params)
    }

    const dialogIndicatorValidation = (indicator, criteria, skill, trainee) => {
        $dialogViews.value.dialogIndicatorValidation = dialog.open(TraineeIndicatorValidation, {
            ...dynamicDialogDefaults,
            props: {
                ...dynamicDialogDefaults.props,
                header: "Validation d'un indicateur",
                dismissableMask: true,
            },
            data: {
                indicator,
                criteria,
                skill,
                trainee,
            }
        });
    }

    // Trainee

    const dialogTraineeEdit = (trainee, params) => {
        return $dialogViews.value.dialogTraineeEdit = traineeEdit(trainee, params)
    }
    const dialogTraineeActivate = (params) => {
        return $dialogViews.value.dialogTraineeActivate = traineeActivate(params)
    }
    const dialogTraineeResendEmailActivation = (params) => {
        return $dialogViews.value.dialogTraineeResendEmailActivation = traineeResendEmailActivation(params)
    }
    const dialogTraineeAccessTokenView = (params) => {
        return $dialogViews.value.dialogAccessTokenView = traineeAccessTokenView(params)
    }
    const dialogTraineeJobForm = (trainee, job, params) => {
        return $dialogViews.value.dialogAccessTokenView = traineeJobForm(trainee, job, params)
    }
    const dialogTraineeQualificationForm = (trainee, job, params) => {
        return $dialogViews.value.dialogAccessTokenView = traineeQualificationForm(trainee, job, params)
    }
    const dialogOrganizationView = (item, organization, params) => {
        return $dialogViews.value.dialogOrganizationView = organizationView(item, organization, params)
    }
    const dialogOrganizationEdit = (item, organization) => {
        return $dialogViews.value.dialogOrganizationEdit = organizationEdit(item, organization)
    }

    // expose managed state as return value
    return {
        $dialogViews,

        dialogUserView,
        dialogUserEdit,
        dialogUserAccessTokenView,
        dialogSkills,
        dialogIndicatorValidation,
        dialogUserAccessTokenQRScan,

        dialogTraineeEdit,
        dialogTraineeActivate,
        dialogTraineeResendEmailActivation,
        dialogTraineeAccessTokenView,
        dialogTraineeJobForm,
        dialogTraineeQualificationForm,

        dialogOrganizationView,
        dialogOrganizationEdit,
    }
}
