import {useStorage} from '@vueuse/core'
import {defineStore, acceptHMRUpdate} from 'pinia'
import {computed, ref} from "vue";
import {useRouter} from "vue-router"
import {useMain} from '@/stores/main'
import {useApi} from '@/stores/api'
import {useUser} from "@/models/useUser";
import {useTrainee} from "@/models/useTrainee";


export const useAuth = defineStore('auth', () => {

    const router = useRouter()
    const mainStore = useMain()
    const apiStore = useApi()

    /**
     * States
     */

        // local storage
    const authStatus = useStorage('authStatus', 0)
    const authUserData = useStorage('authUser', {})

    const authUser = ref(null)

    const fetchingUser = ref(false)

    const status = computed(() => (authStatus.value === 204 || authStatus.value === 200))

    const hasUserData = computed(() => (authUserData.value && Object.keys(authUserData.value).length > 0))

    const hasVerified = computed(() => hasUserData.value
        ? authUserData.value.email_verified_at !== null
        : false)

    const isActive = computed(() => hasUserData.value
        ? authUserData.value.activated_at !== null
        : false)

    const authenticated = computed(() => status.value && hasUserData.value)

    const register = async (form, setErrors, processing, isPro = false) => {

        processing.value = true

        try {
            const response = await apiStore.client()
                .post(isPro ? 'register-professional' : 'register-individual', {json: form})

            authStatus.value = response.status
            await authUserFetch()

            processing.value = false
            await router.push({name: 'home'})
        } catch (error) {
            await apiStore.processFormErrors(error, setErrors, processing)
            processing.value = false
        }
    }
    const login = async (form, setErrors, processing, redirectTo) => {

        processing.value = true

        let response = null
        try {
            response = await apiStore.client()
                .post('login', {json: form})
        } catch (error) {
            await apiStore.processFormErrors(error, setErrors, processing)
            processing.value = false
            return false
        }

        await authUserFetch()
        if (hasUserData.value) {
            authStatus.value = response.status
            processing.value = false

            if (redirectTo) {
                window.location.href = redirectTo
            } else {
                await router.push({name: 'home'})
            }
        } else {
            await router.push({name: 'login'})
        }
    }

    const forgotPassword = async (form, setStatus, setErrors, processing) => {

        processing.value = true

        try {
            const response = await apiStore.client().post('forgot-password', {json: form}).json()
            setStatus.value = response.status
        } catch (error) {
            await apiStore.processFormErrors(error, setErrors, processing)
        }

        processing.value = false
    }

    const resetPassword = async (form, setErrors, processing) => {

        processing.value = true

        try {
            const response = await apiStore.client()
                .post('reset-password', {json: form}).json()

            await router.push(
                {
                    name: 'login', query: {reset: btoa(response.status)}
                }
            )
        } catch (error) {
            await apiStore.processFormErrors(error, setErrors, processing)
        }
        processing.value = false
    }

    const changePassword = async (form, setErrors, processing) => {

        processing.value = true

        try {
            const response = await apiStore.client()
                .post('change-password', {json: form}).json()
            return response
        } catch (error) {
            await apiStore.processFormErrors(error, setErrors, processing)
        }
        processing.value = false
    }

    const exportData = async (setErrors, processing) => {

        processing.value = true

        try {
            const response = await apiStore.client()
                .post('api/privacy/export', {}).json()
            processing.value = false
            return response
        } catch (error) {
            await apiStore.processFormErrors(error, setErrors, processing)
        }
        processing.value = false
    }

    const resendEmailVerification = async (setStatus, setErrors, processing) => {
        processing.value = true
        let response = null
        try {
            response = await apiStore.client()
                .post('email/verification-notification')
            setStatus.value = response.status
        } catch (error) {
            setErrors.value = mainStore.catchCommonErrors(error)
        }
        processing.value = false
    }

    const logout = async () => {
        try {
            await apiStore.client().post('logout')

            router.push({
                name: 'login', query: {loggedout: btoa('Vous avez été déconnecté')}
            })

            $reset()
            mainStore.resetAllStores()
        } catch (error) {
            let errors = await mainStore.catchCommonErrors(error)

            if (!errors) {
                if (error.response.status === 401) {
                    router.push({
                        name: 'login', query: {loggedout: '1'}
                    })
                    $reset()
                    mainStore.resetAllStores()
                } else if (error.response.status !== 422) throw error
            }
        }
    }
    const authUserFetch = async () => {
        if (fetchingUser.value) return false
        fetchingUser.value = true

        let response
        try {
            response = await apiStore.client().get('api/user').json()
        } catch (error) {
            const errors = await mainStore.catchCommonErrors(error)

            if (errors) {
                if (errors.status === 401) {
                    $reset()
                }
                // if (error.response.status !== 409) throw error
            }
            await router.push({name: 'home'})
            fetchingUser.value = false
            return false
        }

        if(response) {
            if (!authUser.value) {
                if (response.data.roles.find((role) => role.name === 'trainee')) {
                    authUser.value = useTrainee({...response.data})
                } else {
                    authUser.value = useUser({...response.data})
                }
            } else {
                authUser.value.setData(response.data)
            }

            authUserData.value = authUser.value.$data

            mainStore.connectivityStatus = 'ok'
            fetchingUser.value = false
        }
    }

    const $reset = () => {
        authStatus.value = 0
        authUser.value = null
        authUserData.value = {}
    }

    return {
        authUser,
        authStatus,
        status,
        hasUserData,
        hasVerified,
        isActive,
        authenticated,
        authUserFetch,
        register,
        login,
        forgotPassword,
        changePassword,
        exportData,
        resetPassword,
        resendEmailVerification,
        logout,
        $reset,
    }
})

if (import.meta.hot) {
    import.meta.hot.accept(acceptHMRUpdate(useAuth, import.meta.hot))
}
