<script setup>
import {ref, inject, onBeforeMount, computed} from "vue";

import Button from "primevue/button";
import FormFieldError from "@/components/FormFieldError.vue";
import ImagePicker from "@/components/picker/Image.vue";
import InputText from "primevue/inputtext";
import Avatar from "primevue/avatar";
import ProgressSpinner from "primevue/progressspinner";
import ValidationErrors from "@/components/ValidationErrors.vue";
import PickerRidet from '@/components/picker/Ridet.vue'
import InlineMessage from "primevue/inlinemessage";
import InputMask from "primevue/inputmask";

import {useUsers} from "@/stores/users";
import {useUser} from "@/models/useUser";
import {useImages} from "@/lib/images";
import {useAuth} from "@/stores/auth";

import {useToast} from 'primevue/usetoast';

import {useVuelidate} from '@vuelidate/core'
import {required, serverSide, email, phoneNC} from '@/lib/validators'

const usersStore = useUsers()
const toast = useToast();

const props = defineProps({
  item: {
    type: Object,
  }
})

const emit = defineEmits(['updated'])

const dialogRef = inject('dialogRef')

const $item = ref(null)

const $loading = ref(true)
const $ridet = ref(null)

const form = ref({})
const $avatar = ref()

const processing = ref(false)
const setErrors = ref()

// Template Refs
const avatar = ref(null)
const avatarUploaded = async (image) => {
  const images = useImages()
  $avatar.value = image
  images.ratioResize($avatar)
  clearErrors()
}

const avatarUrl = computed(() => $avatar.value ? URL.createObjectURL($avatar.value) : $item.value.avatarUrl)

const ridetNumber = computed(() => {
  if ($item.value.$data.organization) {
    return `${$item.value.$data.organization.rid7}.${$item.value.$data.organization.rideta}`
  }
  return null
})

/**
 * Computed
 */
const errors = computed(() => {
  return setErrors.value ? setErrors.value.errors : null
})

const errorMessage = computed(() => {
  //if (setErrors.value && undefined !== setErrors.value.message) return setErrors.value.message
  if (setErrors.value?.errors.general && undefined !== setErrors.value?.errors.general) return setErrors.value?.errors.general
  if (v$.value.$invalid) return "Une ou plusieurs erreurs sont présentes dans le formulaire.\n" +
      "Merci de rectifier votre saisie."
  return null
})

const canSubmit = computed(() => {
  return v$.value.$invalid
})

const isRidetDisabled = computed(() => {
    if(!useAuth().authUser.isSuperAdmin && !useAuth().authUser.isAdmin) {
        return true
    }

    if( useAuth().authUser.id === $item.value.id) {
        return true
    }

    return false
})

const ridetSelected = (ridet) => {
  $ridet.value = ridet
}

const closeDialog = () => {
  if (dialogRef && dialogRef.value) dialogRef.value.close($item)
}

const submit = async () => {
  clearErrors()
  const isFormCorrect = await v$.value.$validate()
  if (!isFormCorrect) return
  const data = {
    ...form.value,
    ridet: $ridet.value,
    avatar: '',
  }

  if ($avatar.value) {
    data.avatar = $avatar.value
  }

  const response = await $item.value.saveForm(data, processing, setErrors)
  if (response) {
    emit('updated', $item)
    toast.add({severity: 'success', summary: 'Effectué.', detail: useAuth().authUser.id === $item.value.id ? 'Informations personnelles modifiées avec succès' : 'Utilisateur modifié avec succès', life: 3000});
    closeDialog()
  }

  await v$.value.$validate()
}

/**
 * Form validation
 */
const rules = {
  ridet: {serverSide: serverSide({field: 'ridet', errors})},
  avatar: {serverSide: serverSide({field: 'avatar', errors})},
  lastname: {required, serverSide: serverSide({field: 'lastname', errors})},
  firstname: {required, serverSide: serverSide({field: 'firstname', errors})},
  position: {required, serverSide: serverSide({field: 'position', errors})},
  phone: {required, phoneNC, serverSide: serverSide({field: 'phone', errors})},
  email: {required, email, serverSide: serverSide({field: 'email', errors})},
}

const v$ = useVuelidate(rules, form, {$lazy: true})

const clearErrors = () => {
  return setErrors.value = null
}

onBeforeMount(async () => {
  if (dialogRef && dialogRef.value.data && dialogRef.value.data) {
    $item.value = dialogRef.value.data.item
  } else {
    $item.value = props.item
  }

  if ($item.value) {
    usersStore.getById($item.value.$data.id || $item.value, true).then((record) => {
      $item.value = record
      form.value = $item.value.formDataObject
      form.value.phone = $item.value.meta('phone')
      form.value.position = $item.value.meta('position')
      $loading.value = false
    })
  } else {
    $item.value = useUser()
    $loading.value = false
  }
})
</script>
<template>
  <div v-if="$loading" class="text-center">
    <ProgressSpinner style="width: 50px; height: 50px" strokeWidth="5"></ProgressSpinner>
  </div>
  <form @submit.prevent="submit" class="flex flex-column gap-4" v-else>
    <div>
      <label class="p-label mb-2">Organisme</label>
      <PickerRidet :default="ridetNumber" @selected="ridetSelected" @hide="v$.ridet.$touch" autofocus
                   label="Selectionnez un organisme" :disabled="isRidetDisabled"/>
      <FormFieldError :errors="v$.ridet.$error ? v$.ridet.$errors : [] " class="text-center mt-2"/>
    </div>
    <div>
      <label class="p-label">Photo de profil</label>

      <div class="flex w-full gap-3 mt-3 mb-3 flex-md">
        <div>
          <Avatar ref="avatar" :image="avatarUrl" class="mr-2" size="xlarge" shape="circle"/>
        </div>
        <div>
          <ImagePicker choose-label="Nouvelle photo" @uploaded="avatarUploaded" avatar/>
          <FormFieldError :errors="v$.avatar.$error ? v$.avatar.$errors : [] " class="text-center mt-2"/>
          <small>Une image JPEG ou PNG, de moins de 2mo. Préférez un avatar carré.</small>
        </div>
      </div>
    </div>

    <div>
      <label for="lastname" class="p-label">Nom</label>
      <div class="p-inputgroup">
        <InputText
            id="lastname"
            v-model="form.lastname"
            type="text"
            class=""
            autocomplete="family-name"
            @blur="v$.lastname.$touch"
            @focus="clearErrors"
            :class="{ 'p-invalid': v$.lastname.$error }"/>
      </div>
      <FormFieldError :errors="v$.lastname.$error ? v$.lastname.$errors : [] "/>
    </div>
    <div>
      <label for="firstname" class="p-label">Prénom</label>
      <div class="p-inputgroup">
        <InputText
            id="firstname"
            v-model="form.firstname"
            type="text"
            class=""
            autocomplete="family-name"
            @blur="v$.firstname.$touch"
            @focus="clearErrors"
            :class="{ 'p-invalid': v$.firstname.$error }"/>
      </div>
      <FormFieldError :errors="v$.firstname.$error ? v$.firstname.$errors : [] "/>
    </div>
    <div>
      <label for="position" class="p-label">Poste occupé</label>
      <div class="p-inputgroup">
        <InputText
            id="position"
            v-model="form.position"
            type="text"
            class=""
            autocomplete="family-name"
            @blur="v$.position.$touch"
            @focus="clearErrors"
            :class="{ 'p-invalid': v$.position.$error }"/>
      </div>
      <FormFieldError :errors="v$.position.$error ? v$.position.$errors : [] "/>
    </div>
    <div>
      <div class="">
        <label for="phone" class="p-label">Numéro de téléphone</label>
        <div class="p-inputgroup">
          <span class="p-inputgroup-addon"><i class="mdi mdi-phone-outline"></i></span>
          <InputMask
              id="phone"
              v-model="form.phone"
              type="phone"
              class=""
              autocomplete="tel-national"
              @blur="v$.phone.$touch"
              @focus="clearErrors"
              mask="99.99.99"
              :class="{ 'p-invalid': v$.phone.$error }"/>

        </div>
      </div>
      <FormFieldError :errors="v$.phone.$error ? v$.phone.$errors : [] "/>
    </div>
    <div>
      <label for="email" class="p-label">E-mail</label>
      <div class="p-inputgroup">
        <span class="p-inputgroup-addon"><i class="mdi mdi-email-outline"></i></span>
        <InputText
            id="email"
            v-model="form.email"
            type="email"
            class=""
            autocomplete="email"
            @blur="v$.email.$touch"
            @focus="clearErrors"
            :class="{ 'p-invalid': v$.email.$error }"/>
      </div>
      <FormFieldError :errors="v$.email.$error ? v$.email.$errors : [] "/>
        <InlineMessage severity="warn" class="mt-2" v-if="$item.$$('pending_email')" :closable="false">
            <small>Un changement d'e-mail pour
                <i>{{ $item.$$('pending_email') }}</i> est en attente de validation.</small>
        </InlineMessage>

    </div>


    <ValidationErrors class="mb-0 w-full" :error="errorMessage"/>

    <div class="flex justify-content-between align-items-center mt-0">
      <Button
          label="Annuler"
          class="p-0"
          link
          @click="closeDialog"
          severity="secondary"
      >

      </Button>

      <Button type="submit" icon="mdi mdi-login" :loading="processing" :disabled="canSubmit"
              label="Enregistrer"/>
    </div>

  </form>
</template>
