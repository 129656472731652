import {onMounted, ref} from "vue";

export function useAppUpdate() {

    const refreshing = ref(false)
    const registration = ref(null)
    const updateExists = ref(false)

    const updateAvailable = (event) => {
        registration.value = event.detail
        updateExists.value = true
    }

    const refreshApp = () => {
        updateExists.value = false
        // Make sure we only send a 'skip waiting' message if the SW is waiting
        if (!registration.value || !registration.value.waiting) return
        // send message to SW to skip the waiting and activate the new SW
        registration.value.waiting.postMessage({type: 'SKIP_WAITING'})
    }

    onMounted(() => {
        document.addEventListener('swUpdated', updateAvailable, {once: true})

        // Prevent multiple refreshes
        if(navigator.serviceWorker) {
            navigator.serviceWorker.addEventListener('controllerchange', () => {
                if (refreshing.value) return
                refreshing.value = true
                // Here the actual reload of the page occurs
                window.location.reload()
            })
        }
    })

    // expose managed state as return value
    return {
        refreshing,

        registration,
        updateExists,
        updateAvailable,
        refreshApp,
    }
}
