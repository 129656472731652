<script setup>
import Card from "primevue/card";
import Button from "primevue/button";

import {ref, computed, inject, onMounted} from "vue";

import ProgressSpinner from "primevue/progressspinner";
import TabView from 'primevue/tabview';
import TabPanel from 'primevue/tabpanel';

import OrganizationEdit from '@/components/Organization/Edit.vue'
import UserItem from '@/components/User/Item.vue'
import TraineeItem from '@/components/Trainee/Item.vue'

import {useUsers} from '@/stores/users';
import {useTrainees} from '@/stores/trainees';
import {useDialog} from 'primevue/usedialog';
import {useAuth} from "@/stores/auth";
import NoRecords from "@/components/NoRecords.vue";

const dynamicDialogDefaults = inject('dynamicDialogDefaults')

const props = defineProps({
    item: {
        type: Object,
        required: true,
    },
    showTabs: {
        type: Array,
        default: ()=>['users', 'trainees'],
    }
})

const dialog = useDialog();
const $dialogView = ref(null)

const usersStore = useUsers()
const traineesStore = useTrainees()

const $usersLoading = ref(!!props.item.$data.users.length)
const $traineesLoading = ref(!!props.item.$data.trainees.length)

const $users = ref([])
const $trainees = ref([])

const ridet_name = computed(() => {
    const ridet_name = props.item.$$('ridet_sign') || props.item.$$('ridet_name')
    if (ridet_name !== props.item.$$('display_name')) {
        return ridet_name
    }
    return null
})

const ridet_city = computed(() => {
    if (props.item.$$('ridet_raw')) {
        const ridet_city = props.item.$$('ridet_raw').fields.libelle_commune ?? null

        if (ridet_city && ridet_city !== props.item.$$('city')) {
            return ridet_city
        }
    }

    return null
})

const dialogEdit = () => {
    $dialogView.value = dialog.open(OrganizationEdit, {
        ...dynamicDialogDefaults,
        props: {
            ...dynamicDialogDefaults.props,
            header: props.item.id === useAuth().authUser.organization.id ? "Modifier votre organisme" : "Modifier un organisme"
        },
        data: {
            item: props.item,
        }
    });
}

const loadUsers = async () => {
    if (!props.item.$data.users.length) return
    $usersLoading.value = true
    const response = await usersStore.fetch({searchParams: {organization_id: props.item.id}})
    if (response) {
        $users.value = response.data
    }
    $usersLoading.value = false
}

const loadTrainees = async () => {
    if (!props.item.$data.trainees.length) return
    $traineesLoading.value = true
    const response = await traineesStore.fetch({searchParams: {organization_id: props.item.id}})
    if (response) {
        $trainees.value = response.data
    }
    $traineesLoading.value = false
}

const changeTab = async (index) => {
    if (0 === index && !$users.value.length) {
        await loadUsers()
    } else if (1 === index && !$trainees.value.length) {
        await loadTrainees()
    }
}

const onUserDeleted = (item) => {
    $users.value.forEach((value, index) => {
        if (value.id === item.id) {
            $users.value.splice(index, 1)
        }
    })
}

const onTraineeDeleted = (item) => {
    $trainees.value.forEach((value, index) => {
        if (value.id === item.id) {
            $trainees.value.splice(index, 1)
        }
    })
}

onMounted(async () => {
    loadUsers()
})
</script>
<template>
    <Card>
        <template #title>
            <div class="flex">
                <div class="flex-grow-1">
                    <div class="p-text-tertiary-color font-family-secondary font-bold">{{
                            item.$$('display_name')
                        }}
                    </div>
                    <div class="text-sm font-normal">
                        <span v-if="ridet_name">{{ ridet_name }} <br></span>
                        <span>{{ item.$$('ridet') }} <span v-if="ridet_city"> - {{ ridet_city }}</span></span><br>
                        <span>{{ item.$$('libelle_naf') }}</span>
                    </div>
                </div>
                <div class="flex-grow-0">
                    <img :src="item.logoUrl" style="width: 100%; height:auto;" class="max-w-5rem max-h-5rem">
                </div>
            </div>
        </template>
        <template #content>
            <div class="flex align-content-start">
                <div><i class="mdi mdi-map-marker-outline mr-2 text-xl p-text-primary-color"/></div>
                <div>
                    <div v-if="item.$$('address')">
                        <div>{{ item.$$('address') }}</div>
                        <div v-if="item.$$('address2')">{{ item.$$('address2') }}</div>
                    </div>
                    <div>{{ item.$$('city') }}</div>
                </div>
            </div>
            <div class="flex align-items-center" v-if="item.$$('phone')">
                <div><i class="mdi mdi-phone-outline mr-2 text-xl p-text-primary-color"/></div>
                <div>
                    <div><a :href="`tel: +687 ${item.$$('phone')}`">{{ item.$$('phone') }}</a></div>
                </div>
            </div>
            <div class="flex align-items-center" v-if="item.$$('email')">
                <div><i class="mdi mdi-email-outline mr-2 text-xl p-text-primary-color"/></div>
                <div class="pb-1">
                    <a :href="`mailto: ${item.$$('display_name')} <${item.$$('email')}>`">{{ item.$$('email') }}</a>
                </div>
            </div>
        </template>
        <template #footer>
            <div class="text-right">
                <Button icon="mdi mdi-pencil" label="Modifier" severity="primary" style="margin-left: 0.5em"
                        v-if="item.canEdit" @click="() => dialogEdit()"/>
            </div>
        </template>
    </Card>
    <div class="flex align-items-center mt-3">
        <div class="flex-grow-0">
<!--            <Button icon="mdi mdi-account-plus" label="Inviter un utilisateur" severity="secondary" outlined
                    size="small"/>-->
        </div>

    </div>

    <TabView :scrollable="true" @update:activeIndex="changeTab" class="p-tabview-tertiary">
        <TabPanel header="Utilisateurs" v-if="showTabs.includes('users')">
            <div clas="data-list">
                <div v-if="$usersLoading" class="text-center">
                    <ProgressSpinner style="width: 50px; height: 50px" strokeWidth="5"></ProgressSpinner>
                </div>
                <div v-else>

                    <div v-if="$users.length" class="data-list">
                        <div v-for="user in $users" :key="user.$data.id" class="item">
                            <UserItem :item="user" @deleted="()=> onUserDeleted(user)"/>
                        </div>
                    </div>
                    <NoRecords v-else>
                        Aucun utilisateur.
                    </NoRecords>
                </div>
            </div>
        </TabPanel>
        <TabPanel header="Stagiaires" v-if="showTabs.includes('trainees')">
            <div clas="data-list">
                <div v-if="$traineesLoading" class="text-center">
                    <ProgressSpinner style="width: 50px; height: 50px" strokeWidth="5"></ProgressSpinner>
                </div>
                <div v-else>

                    <div v-if="$trainees.length" class="data-list">
                        <div v-for="user in $trainees" :key="user.$data.id" class="item">
                            <TraineeItem :item="user"  @deleted="()=> onTraineeDeleted(user)"/>
                        </div>
                    </div>
                    <div v-else>Aucun stagiaire</div>
                </div>
            </div>
        </TabPanel>
    </TabView>
</template>
