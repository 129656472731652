<script setup>
import {computed, inject, markRaw, onBeforeMount, ref} from "vue";

import {useMain} from '@/stores/main'
import {useAuth} from '@/stores/auth'
import {useRoute, useRouter} from "vue-router";

import DynamicDialog from 'primevue/dynamicdialog';
import HiveLoadingIndicator from '@/components/HiveLoadingIndicator.vue'

import UI from './UI.vue'
import UIPrintable from './UIPrintable.vue'
import ConfirmDialog from "primevue/confirmdialog";
import Toast from "primevue/toast";

const mainStore = useMain()
const authStore = useAuth()
const router = useRouter()
const route = useRoute()

const dynamicDialogDefaults = inject('dynamicDialogDefaults')

const $loading = ref(authStore.authenticated ? true : false)
const UIComponent = computed(() => {
    if (route.meta.printable) {
        return markRaw(UIPrintable)
    } else {
        return markRaw(UI)
    }
})


onBeforeMount(async () => {
    await mainStore.loadAppConfig()
    if (authStore.authenticated && mainStore.isNetworkConnected) {
        await authStore.authUserFetch()
        await mainStore.loadSkills()

        if (!authStore.status || !authStore.hasUserData) {
            await router.push({name: 'login'})
        }
        $loading.value = false
    }
})

</script>

<template>

    <div v-if="$loading" class="text-center w-screen h-full flex justify-content-center">
        <HiveLoadingIndicator :size="120"/>
    </div>
    <component :is="UIComponent" :loading="$loading" v-else>
        <router-view></router-view>
    </component>
    <DynamicDialog></DynamicDialog>
    <ConfirmDialog :breakpoints="dynamicDialogDefaults.props.breakpoints">
<!--      <template #message="slotProps">
        <div class="flex align-items-center p-4">
          <i :class="slotProps.message.icon" class="text-5xl"></i>
          <p class="pl-2" v-html="slotProps.message.message"></p>
        </div>
      </template>-->
    </ConfirmDialog>

  <Toast group="ns"/>
  <Toast/>
</template>
