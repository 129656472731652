<script setup>

import {computed, inject, onMounted, ref} from "vue"

import Button from 'primevue/button'
import InputMask from 'primevue/inputmask'
import Message from 'primevue/message';

import {useAuth} from "@/stores/auth";
import {useRouter} from "vue-router";
import {useDialogs} from "@/composables/useDialogs";

const authStore = useAuth()
const router = useRouter()

const dialogRef = inject('dialogRef')

const {dialogUserAccessTokenQRScan} = useDialogs()

const accessToken = ref(null)
const errors = ref(null)
const errorsQR = ref(null)
const errorsUrl = ref(null)
const errorsToken = ref(null)
const successQR = ref(null)
const successUrl = ref(null)
const successToken = ref(null)

const processingQR = ref(false)
const processingUrl = ref(false)
const processingToken = ref(false)
const processingAPI = ref(false)

const $dialogUserAccessTokenQRScan = ref(null)

const processing = computed(() => {
    return processingQR.value || processingUrl.value || processingToken.value
})

const submitUrl = async () => {
    errorsUrl.value = false
    processingUrl.value = true
    successUrl.value = null

    await submit(tokenUri.value + accessToken.value)

    if (errors.value) {
        errorsUrl.value = true
        processingUrl.value = false
    } else {
        successUrl.value = true
        await timeout(2000)

        dialogRef.value.close()
        processingUrl.value = false
    }

}

const scanQR = () => {
    $dialogUserAccessTokenQRScan.value = dialogUserAccessTokenQRScan({
        onClose: async (options) => {


            if (options.data) {
                errorsQR.value = false
                processingQR.value = true
                successQR.value = null

                await submit(options.data.url)

                if (errors.value) {
                    errorsQR.value = true
                    processingQR.value = false
                } else {
                    successQR.value = true
                    await timeout(2000)

                    dialogRef.value.close()
                    processingQR.value = false
                }
            }
        }
    })
}

const submitToken = async (token) => {

    errorsToken.value = false
    processingToken.value = true
    successToken.value = null

    await submit(tokenUrl(token))

    if (errors.value) {
        errorsToken.value = true
        processingToken.value = false
    } else {
        successToken.value = true
        await timeout(2000)

        dialogRef.value.close({noRedirect: true})
        processingToken.value = false
    }
}

const submit = async (url) => {
    errors.value = null
    const retval = await authStore.authUser.validateTraineAccessToken(url, errors, processingAPI)
    if (retval) {
        await router.push({name: 'trainee', params: {id: retval}})
    }
}

const tokenUrl = (token = '') => {
    const uri = new URL(`/p/` + token, location.href).href

    return uri
}

const tokenUri = computed(() => {
    return tokenUrl()
})

const timeout = (ms) => {
    return new Promise((resolve) => {
        window.setTimeout(resolve, ms)
    })
}

onMounted(() => {
    if (dialogRef.value.data.token) {
        submitToken(dialogRef.value.data.token)
    }
})

</script>

<template>
    <p>Pour ajouter un stagiaire et accéder à son Passeport B.E.E., scannez le code QR ou saisissez le lien qu'il vous
        présente.</p>

    <div v-if="errorsToken">
        <Message severity="error" :closable="false">{{ errors.message }}</Message>
    </div>
    <div v-if="successToken">
        <Message severity="success" :closable="false">Vous avez maintenant accès au passeport B.E.E. de ce stagiaire.
        </Message>
    </div>

    <div>
        <div>
            <h3>Par code QR</h3>
            <div v-if="errorsQR">
                <Message severity="error" :closable="false">{{ errors.message }}</Message>
            </div>
            <div v-if="successQR">
                <Message severity="success" :closable="false">Vous avez maintenant accès au passeport B.E.E. de ce
                    stagiaire.
                </Message>
            </div>
            <div class="flex justify-content-center">
                <Button label="Scanner le QR code"
                        :icon="processing ? 'mdi mdi-loading mdi-spin' : 'mdi mdi-qrcode-scan'" size="large"
                        :aria-disabled="processing"
                        :disabled="processing" @click="scanQR"></Button>
            </div>
        </div>

        <div>
            <h3>Par lien</h3>
            <div v-if="errorsUrl">
                <Message severity="error" :closable="false">{{ errors.message }}</Message>
            </div>
            <div v-if="successUrl">
                <Message severity="success" :closable="false">Vous avez maintenant accès au passeport B.E.E. de ce
                    stagiaire.
                </Message>
            </div>
            <form @submit.prevent="submitUrl">
                <div class="flex flex-column justify-content-center sm:flex-row gap-4">

                    <div class="p-inputgroup flex-1">
                        <span class="p-inputgroup-addon"><strong>{{ tokenUri }}</strong></span>
                        <InputMask v-model="accessToken" mask="******" :aria-disabled="processing"
                                   :disabled="processing"/>
                    </div>
                    <Button type="submit" label="Valider" :aria-disabled="processing" :disabled="processing"
                            @click="submitUrl"
                            :icon="processing ? 'mdi mdi-loading mdi-spin' : 'mdi mdi-progress-check'"></Button>

                </div>
            </form>
        </div>
    </div>

</template>
