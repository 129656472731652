<script setup>
import {/*computed,*/ ref, onMounted, computed} from "vue"

import InputText from "primevue/inputtext"
import Button from "primevue/button"
import Dialog from 'primevue/dialog'

import OrganizationRidet from '@/components/Organization/Ridet.vue'
import {useOrganizations} from "@/stores/organizations"
import Skeleton from "primevue/skeleton"
import Message from "primevue/message"

const organizationStore = useOrganizations()

const emit = defineEmits(['selected', 'hide'])

const props = defineProps({
    default: {
        type: [String],
    },
    disabled: {
        type: [Boolean],
        default: false,
    },
    label: {
        type: [String],
        default: 'Sélectionnez votre organisme',
    },
    autofocus: Boolean,
})

const selected = ref(props.default || null)
const selectedItem = ref()

const autofocus = ref(!!props.autofocus)

const picking = ref(false)
const records = ref()
const q = ref()

const searchInput = ref()

const loading = ref(false);
const querying = ref(false);

const loadingError = ref()
const queryingError = ref()

const pickerClasses = computed(() => {
    return {
        'ridet-item': true,
        'p-3': true,
        'cursor-pointer': !isDisabled.value,
        'data-list-item': true,
        'pickable': true,
    }
})

const isDisabled = computed(() => {
    if(props.disabled) return true
    return false
})

const setPicking = (state = true) => {
    return picking.value = state && !isDisabled.value
}

let timeout = null;
const keyup = () => {
    if (q.value && q.value.length < 3) return;

    querying.value = true
    if (timeout) {
        clearTimeout(timeout)
    }

    timeout = setTimeout(async () => {
        try {
            const result = await organizationStore.queryRidet(q.value)
            records.value = result.records
        } catch (e) {
            queryingError.value = e.message
        }
        querying.value = false
    }, 500)
}

const dialogShow = () => {
    //searchInput.value.$el.focus()
}
const itemClasses = (item) => {
    let classes = 'item p-3 cursor-pointer'
    if (selectedItem.value && (`${selectedItem.value.fields.rid7}.${selectedItem.value.fields.ndegetablissement}` === `${item.fields.rid7}.${item.fields.ndegetablissement}`)) {
        classes += ' selected'
    }
    return classes
}
const select = (record) => {
    selectedItem.value = record
    selected.value = `${record.fields.rid7}.${record.fields.rideta}`
    emit('selected', record)
    picking.value = false
    //$reset()
}
/*const $reset = () => {
    records.value = {}
    q.value = ''
    picking.value = false
}*/


onMounted(async () => {
    if (!selectedItem.value && selected.value) {
        loading.value = true
        try {
            const result = await organizationStore.queryRidet(selected.value)
            if (result && result.records.length) {
                select(result.records[0])
            } else {
                //loadingError.value = `Le numéro de RIDET ${selected.value} ne correspond a aucun établissement actif au RIDET.`
            }
        } catch (e) {
            loadingError.value = e.message
        }
        loading.value = false
    }
})
</script>
<template>
    <Message v-if="loadingError" severity="error" :closable="false">{{ loadingError }}</Message>
    <div v-if="selected || selectedItem" :class="pickerClasses" @click="()=> {if(!isDisabled) picking=true}">
        <div v-if="loading" class="mt-4 text-center">
            <i class="mdi mdi-spin mdi-loading text-2xl"/>
        </div>
        <div class="flex align-items-center" v-else>
            <OrganizationRidet :ridet="selectedItem" class="w-full" v-if="selectedItem"/>
            <div class="w-full" v-else>
                Aucune établissement actif pour le numéro de RIDET {{ selected }}.
            </div>
            <div class="flex-grow-0" v-if="!isDisabled">
                <i class="mdi mdi-reload" @click="(e)=>setPicking()"></i>
            </div>
        </div>
    </div>
    <div v-else class="text-center">
        <Button :label="label" icon="mdi mdi-button-cursor" @click="(e)=>setPicking()"
                :autofocus="autofocus"/>
    </div>
    <Dialog v-model:visible="picking" modal header="Sélectionner un organisme"
            :style="{ width: '50vw' }"
            :draggable="false"
            :breakpoints="{ '960px': '75vw', '641px': '95vw' }"
            @show="dialogShow"
            @hide="(e)=>emit('hide', e)"
    >
        <p class="p-inputgroup">
            <span class="p-float-label p-input-icon-right">
                <i :class="querying ? 'mdi mdi-loading mdi-spin' : 'mdi mdi-magnify'"/>
                <input-text @keyup="keyup" v-model="q" ref="searchInput" autofocus/>
                <label for="ac">Rechercher</label>
            </span>
        </p>
        Recherche par RIDET ou par nom d'organisme
        <div v-if="querying" class="mt-4">
            <Skeleton width="100%" height="4rem" class="mb-2"></Skeleton>
            <Skeleton width="100%" height="4rem" class="mb-2"></Skeleton>
            <Skeleton width="100%" height="4rem" class="mb-2"></Skeleton>
        </div>
        <div v-if="records && records.length" class="flex flex-column gap-4 data-list">
            <div class="flex flex-column gap-4">
                <div @click="(event)=>select(record, event)" v-for="record in records" :key="record"
                     :class="itemClasses(record)">
                    <OrganizationRidet :ridet="record"/>
                </div>
            </div>

        </div>
    </Dialog>

</template>
