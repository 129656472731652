<script setup>
import {ref, inject, computed, onMounted} from "vue";

import Button from "primevue/button";
import FormFieldError from "@/components/FormFieldError.vue";
import ImagePicker from "@/components/picker/Image.vue";
import InputSwitch from "primevue/inputswitch";
import InputText from "primevue/inputtext";
import ProgressSpinner from "primevue/progressspinner";
import RidetPicker from "@/components/picker/Ridet.vue";
import ValidationErrors from "@/components/ValidationErrors.vue";

import {useDictionaries} from "@/stores/dictionaries";
import {useOrganizations} from "@/stores/organizations";

import {useToast} from 'primevue/usetoast';

import {useVuelidate} from '@vuelidate/core'
import {serverSide/*, required, email, phoneNC*/} from '@/lib/validators'
import InputMask from "primevue/inputmask";
import Dropdown from "primevue/dropdown";
import {useAuth} from "@/stores/auth";

const dictionariesStore = useDictionaries()
const organizationsStore = useOrganizations()
const toast = useToast();

const props = defineProps({
    item: {
        type: Object,
    }
})

const emit = defineEmits(['updated'])

const dialogRef = inject('dialogRef')

const $loading = ref(true)

const $item = ref(dialogRef.value.data.item || props.item || null)
const $organization = ref(null)

const form = ref({})
const $ridet = ref()
const $logo = ref()
const $certified = ref()

const processing = ref(false)
const setErrors = ref()

// Template Refs
const logo = ref(null)
const logoUploaded = async (image) => {
    $logo.value = image
    logo.value.src = image.objectURL
    clearErrors()
}

/**
 * Computed
 */
const isRidetDisabled = computed(() => {
    if (!useAuth().authUser.isSuperAdmin && !useAuth().authUser.isAdmin) {
        return true
    }

    if (useAuth().authUser.organization.id === $item.value.id) {
        return true
    }

    return false
})

const errors = computed(() => {
    return setErrors.value ? setErrors.value.errors : null
})

const errorMessage = computed(() => {
    //if (setErrors.value && undefined !== setErrors.value.message) return setErrors.value.message
    if (setErrors.value?.errors.general && undefined !== setErrors.value?.errors.general) return setErrors.value?.errors.general
    if (v$.value.$invalid) return "Une ou plusieurs erreurs sont présentes dans le formulaire.\n" +
        "Merci de rectifier votre saisie."
    return null
})

const canSubmit = computed(() => {
    return v$.value.$invalid
})

const $city_options = ref([])
const $city_options_loading = ref(true)
const load_cities = () => {
    dictionariesStore.citiesDropdown().then((json) => {
        $city_options_loading.value = false
        $city_options.value = json
    })
}

const closeDialog = () => {
    if (dialogRef.value) dialogRef.value.close($organization)
}

const submit = async () => {
    clearErrors()
    const isFormCorrect = await v$.value.$validate()
    if (!isFormCorrect) return
    const data = {
        display_name: form.value.display_name,
        phone: form.value.phone,
        email: form.value.email,
        address: form.value.address,
        address2: form.value.address2,
        city: form.value.city,
    }

    if ((useAuth().authUser.isSuperAdmin || useAuth().authUser.isAdmin)
        && useAuth().authUser.organization.id !== $item.value.id) {
        data.ridet_raw = $ridet.value
        data.certified = $certified.value ? 1 : 0
    }

    if ($logo.value) {
        data.logo = $logo.value
    }

    if (await $organization.value.saveForm(data, processing, setErrors)) {
        emit('updated', $organization)
        toast.add({severity: 'success', summary: 'Effectué.', detail: 'Organisme modifié avec succès', life: 3000});
        closeDialog()
    }

    await v$.value.$validate()
}

/**
 * Form validation
 */
const rules = {
    ridet_raw: {serverSide: serverSide({field: 'ridet_raw', errors})},
    logo: {serverSide: serverSide({field: 'logo', errors})},
    display_name: {/*required,*/ serverSide: serverSide({field: 'display_name', errors})},
    phone: {/*required, phoneNC,*/ serverSide: serverSide({field: 'phone', errors})},
    email: {/*required, email,*/ serverSide: serverSide({field: 'email', errors})},
    address: {/*required,*/ serverSide: serverSide({field: 'address', errors})},
    address2: {serverSide: serverSide({field: 'address2', errors})},
    city: {/*required,*/ serverSide: serverSide({field: 'city', errors})},
}

const v$ = useVuelidate(rules, form, {$lazy: true})

const clearErrors = () => {
    return setErrors.value = null
}

onMounted( () => {
    load_cities()
    organizationsStore.getById($item.value.$data.id, true).then(async (record) => {
        $organization.value = record
        form.value = $organization.value.formDataObject
        $certified.value = 1 === form.value.certified
        await v$.value.$validate()
        $loading.value = false
    })
})
</script>
<template>
    <div v-if="$loading" class="text-center">
        <ProgressSpinner style="width: 50px; height: 50px" strokeWidth="5"></ProgressSpinner>
    </div>
    <form @submit.prevent="submit" class="flex flex-column gap-4" v-else>
        <div>
            <label class="p-label">RIDET</label>
            <p>
                <RidetPicker :default="$organization.$data.ridet"
                             @selected="(record)=> {$ridet = record; clearErrors()}"
                             :disabled="isRidetDisabled"/>
                <FormFieldError :errors="v$.ridet_raw.$error ? v$.ridet_raw.$errors : [] " class="text-center mt-2"/>
            </p>
        </div>
        <div>
            <label class="p-label">Logo</label>

            <div class="flex w-full gap-3 mt-3 mb-3 flex-md">
                <div>
                    <img ref="logo" :src="$organization.logoUrl"
                         style="width: 100%; height:auto; max-width:200px; max-height:150px"/>
                </div>
                <div>
                    <ImagePicker choose-label="Nouveau logo" @uploaded="logoUploaded"/>
                    <FormFieldError :errors="v$.logo.$error ? v$.logo.$errors : [] " class="text-center mt-2"/>
                    <small>Une image JPEG ou PNG, de moins de 2mo. Préférez un logo carré.</small>
                </div>
            </div>
        </div>

        <div>
            <label for="display_name" class="p-label">Nom</label>
            <div class="p-inputgroup">
                <InputText
                    id="display_name"
                    v-model="form.display_name"
                    type="text"
                    class=""
                    autocomplete="family-name"
                    @blur="v$.display_name.$touch"
                    @focus="clearErrors"
                    :class="{ 'p-invalid': v$.display_name.$error }"/>
            </div>
            <FormFieldError :errors="v$.display_name.$error ? v$.display_name.$errors : [] "/>
        </div>
        <div>
            <div class="">
                <label for="phone" class="p-label">Numéro de téléphone</label>
                <div class="p-inputgroup">
                    <span class="p-inputgroup-addon"><i class="mdi mdi-phone-outline"></i></span>
                    <InputMask
                        id="phone"
                        v-model="form.phone"
                        type="phone"
                        class=""
                        autocomplete="tel-national"
                        @blur="v$.phone.$touch"
                        @focus="clearErrors"
                        mask="99.99.99"
                        :class="{ 'p-invalid': v$.phone.$error }"/>

                </div>
            </div>
            <FormFieldError :errors="v$.phone.$error ? v$.phone.$errors : [] "/>
        </div>
        <div>
            <label for="email" class="p-label">E-mail</label>
            <div class="p-inputgroup">
                <span class="p-inputgroup-addon"><i class="mdi mdi-email-outline"></i></span>
                <InputText
                    id="email"
                    v-model="form.email"
                    type="email"
                    class=""
                    autocomplete="username"
                    @blur="v$.email.$touch"
                    @focus="clearErrors"
                    :class="{ 'p-invalid': v$.email.$error }"/>
            </div>
            <FormFieldError :errors="v$.email.$error ? v$.email.$errors : [] "/>
        </div>
        <div>
            <label for="display_name" class="p-label">Adresse physique</label>
            <div class="p-inputgroup">
                <InputText
                    id="address"
                    v-model="form.address"
                    type="text"
                    class=""
                    autocomplete="family-name"
                    @blur="v$.address.$touch"
                    @focus="clearErrors"
                    :class="{ 'p-invalid': v$.address.$error }"
                    placeholder="Votre adresse physique"
                />
            </div>
            <div class="p-inputgroup">
                <InputText
                    id="address2"
                    v-model="form.address2"
                    type="text"
                    class=""
                    autocomplete="family-name"
                    @blur="v$.address2.$touch"
                    @focus="clearErrors"
                    :class="{ 'p-invalid': v$.address2.$error }"
                    placeholder="Complément d'adresse"
                />
            </div>
            <FormFieldError :errors="v$.address.$error ? v$.address.$errors : [] "/>
            <FormFieldError :errors="v$.address2.$error ? v$.address2.$errors : [] "/>
        </div>
        <div>
            <label for="city" class="p-label">Ville</label>
            <div class="p-inputgroup">
                <Dropdown v-model="form.city" inputId="city" :options="$city_options" option-label="label"
                          option-value="value"
                          class="w-full" :loading="$city_options_loading" filter/>

                <FormFieldError :errors="v$.city.$error ? v$.city.$errors : [] "/>
            </div>
        </div>

        <div v-if="useAuth().authUser.isSuperAdmin || useAuth().authUser.isAdmin">
            <label for="certified" class="p-label ml-0 mb-2">Certifié</label>
            <div class="p-inputgroup">
                <InputSwitch v-model="$certified" inputId="certified" class=""/>
                <i class="mdi mdi-help-rhombus ml-3" style="font-size: 1.5rem; color: var(--color-text)"
                   v-tooltip.top="'Les administrateurs d\'un organisme certifié peuvent modifier les candidats et valider leurs compétences. Ils peuvent aussi certifier leurs propres utilisateurs.'"/>
            </div>
        </div>

        <ValidationErrors class="mb-0 w-full" :error="errorMessage"/>

        <div class="flex justify-content-between align-items-center mt-0">
            <Button
                label="Annuler"
                class="p-0"
                link
                @click="closeDialog"
                severity="secondary"
            >

            </Button>

            <Button type="submit" icon="mdi mdi-login" :loading="processing" :disabled="canSubmit"
                    label="Enregistrer"/>
        </div>

    </form>
</template>
