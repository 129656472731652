<script setup>
import Avatar from 'primevue/avatar'
import {inject} from "vue";
import {mdiShieldCheckOutline} from "@mdi/js";
import SvgIcon from "@jamescoyle/vue-icon";

const dialogRef = inject('dialogRef')

const props = defineProps({
    item: {
        type: Object,
    }
})

const $item = dialogRef.value.data.item || props.item || null

</script>
<template>
    <div class="flex align-items-center">
        <Avatar ref="avatar" :image="$item.avatarUrl" class="mr-2" size="xlarge" shape="circle"/>
        <div class="flex-grow-1">
            <span class="font-family-tradegothic font-bold p-text-tertiary-color text-2xl">{{ $item.$data.lastname }} {{
                    $item.$data.firstname
                }}</span>
            <div v-if="$item.$data.organization" style="color: var(--lt-color-gray-500)" class="text-sm sm:text-base">
              <span v-if="$item.$data.organization.certified" class="inline-block vertical-align-text-top text-success-color mr-1"><svg-icon
                  type="mdi" :path="mdiShieldCheckOutline"
                  :size="16" class="block"/></span>{{ $item.$data.organization.display_name }}<span v-if="$item.meta('position')"><br>
                {{ $item.meta('position') }}</span>
            </div>

        </div>
    </div>
</template>
