<script setup>

import {computed, inject, ref} from "vue";
import Avatar from "primevue/avatar";
import Tag from "primevue/tag";
import Button from "primevue/button";
import {useToast} from "primevue/usetoast";
import {useAuth} from "@/stores/auth";

const toast = useToast()
const dialogRef = inject('dialogRef')
const {indicator, criteria, skill, trainee} = dialogRef.value.data
const $processing = ref(false)

const closeDialog = () => {
  if (dialogRef.value) dialogRef.value.close()
}

const adminValidated = computed(() => {

  return indicator.validation && indicator.validation.admin_validated_at
})

const organizationValidated = computed(() => {
  return indicator.validation && indicator.validation.organization_validated_at
})

const criteriaCode = computed(() => {
  return 'C' + (skill.order + 1) + '-' + (criteria.order + 1)
})

const adminValidatedIconStyles = computed(() => {
  const styles = {
    color: 'var(--gray-300)'
  }

  if (adminValidated.value) {
    styles.color = 'var(--green-500)'
  }
  return styles
})

const organizationValidatedIconStyles = computed(() => {
  const styles = {
    color: 'var(--gray-300)'
  }

  if (organizationValidated.value) {
    styles.color = 'var(--green-500)'
  }
  return styles
})

const canSubmit = computed(() => {
  if( useAuth().authUser.hasTrainee(trainee) && !useAuth().authUser.isSuperAdmin) {
    return !organizationValidated.value
  }
  return !adminValidated.value
})

const validateSubmit = async () => {
  $processing.value = true
  const response = await trainee.validateIndicator(indicator)
  $processing.value = false

  if (response) {
    toast.add({
      severity: 'success',
      summary: 'Enregistré',
      detail: "Indicateur validé.",
      life: 5000
    })
    closeDialog()
  } else {
    toast.add({
      severity: 'error',
      summary: 'Erreur',
      detail: "Impossible de valider l'indicateur.",
      life: 5000
    })
  }
  return true
}


</script>
<template>
  <div class="flex flex-column gap-4">
    <div class="flex align-items-center gap-4">
      <Avatar ref="avatar" :image="trainee.avatarUrl" class="mr-2 flex-shrink-0" size="large" shape="circle"/>
      <div class="flex-grow-1">
        <div class="font-family-tradegothic font-bold">{{ trainee.$data.lastname }} {{
            trainee.$data.firstname
          }}
        </div>
      </div>
    </div>

    <div class="data-list-item p-3 flex flex-column gap-2">
      <div>
        <Tag :value="criteriaCode" class="font-family-tertiary text-base skill"/>
        <span class="font-family-secondary font-bold ml-2">{{ criteria.name }}</span>
      </div>
      <div>
        <span
            class="font-family-secondary font-bold p-text-tertiary-color text-xs">Indicateur {{
            indicator.order + 1
          }}</span>
        <span>{{ indicator.name }}</span>
      </div>
    </div>

    <div class="data-list-item p-3 flex flex-column gap-2">
      <h4 class="mt-0 mb-2">Statut de l'indicateur</h4>
      <div>
        <i class="mdi mdi-check-circle-outline mr-1"
           :style="adminValidatedIconStyles"/>
        <span v-if="adminValidated">Validé par le SPOT</span>
        <span v-else>En attente de validation SPOT</span>
      </div>
      <div>
        <i class="mdi mdi-check-circle-outline mr-1" :style="organizationValidatedIconStyles"/>
        <span v-if="organizationValidated">Validé par un employeur</span>
        <span v-else>En attente de validation employeur</span>
      </div>
    </div>
    <div v-if="trainee.canValidateIndicator">
      <p>En tant qu'administrateur vous pouvez apporter votre validation.</p>
      <p>Souhaitez vous valider cette indicateur ?</p>
    </div>
    <div class="flex justify-content-between align-items-center mt-0">
      <Button
          label="Fermer"
          class="p-0"
          link
          @click="closeDialog"
          severity="secondary"
      >

      </Button>

      <Button type="button" icon="mdi mdi-check-circle-outline" :loading="$processing" :disabled="! canSubmit"
              @click="validateSubmit"
              label="Valider l'indicateur" v-if="trainee.canValidateIndicator"/>
    </div>
  </div>
</template>
