import {markRaw} from "vue";
import {useDialog} from "primevue/usedialog";
import UserView from "@/components/User/View.vue";
import UserViewHeader from "@/components/User/ViewHeader.vue";
import DialogAccessToken from "@/components/User/DialogAccessToken.vue";
import DialogAccessTokenQRScan from "@/components/User/DialogAccessTokenQRScan.vue";
import UserEdit from "@/components/User/Edit.vue";
export function dialogsUsers(dynamicDialogDefaults) {

    const dialog = useDialog()

    const userView = (item, organization, params={}) => {

        if(organization && !item.$data.organization ) item.$data.organization = organization

        return dialog.open(UserView, {
            ...dynamicDialogDefaults,
            props: {
                ...dynamicDialogDefaults.props,
                header: 'Utilisateur',
                dismissableMask: true,
            },
            templates: {
                header: markRaw(UserViewHeader),
            },
            data: {
                item,
            },
            ...params,
        });
    }

    const userEdit = (item) => {
        return dialog.open(UserEdit, {
            ...dynamicDialogDefaults,
            props: {
                ...dynamicDialogDefaults.props,
                header: item && item.hasData ? "Modifier un utilisateur" : "Ajouter un utilisateur"
            },
            data: {
                item,
            }
        });
    }

    const userAccessTokenView = (params) => {
        return dialog.open(DialogAccessToken, {
            ...dynamicDialogDefaults,
            props: {
                ...dynamicDialogDefaults.props,
                header: 'Ajouter un stagiaire',
                dismissableMask: true,
            },
            /*templates: {
                header: markRaw(DialogAccessTokenHeader),
            },*/
            data: {
            },
            ...params,
        });
    }
    const userAccessTokenQRScan = (params) => {
        return dialog.open(DialogAccessTokenQRScan, {
            ...dynamicDialogDefaults,
            props: {
                ...dynamicDialogDefaults.props,
                header: 'Scanner le QR code du stagiaire à ajouter',
                dismissableMask: true,
            },
            /*templates: {
                header: markRaw(DialogAccessTokenHeader),
            },*/
            data: {
            },
            ...params,
        });
    }

    return {
        userView,
        userEdit,
        userAccessTokenView,
        userAccessTokenQRScan,
    }
}
