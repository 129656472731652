import {useBase} from "@/models/useBase";
import {computed, /*isRef,*/ ref} from "vue";
import {useApi} from "@/stores/api";
import {useAuth} from "@/stores/auth";
import {useTrainee} from "@/models/useTrainee";
import {useOrganizations} from "@/stores/organizations";
import {useMain} from "@/stores/main";
import {useDate} from "@/composables/useDate";

export function useUser(data = {}, type, formFields) {
    //data = JSON.parse(JSON.stringify(isRef(data) ? data.value : data))

    const $data = ref(data)
    if (!type) {
        type = 'user'
    }
    if (!formFields) {
        formFields = {
            ridet: '',
            firstname: '',
            lastname: '',
            position: '',
            email: '',
            phone: '',
            avatar: null,
        }
    }

    const {
        hasData,
        formData,
        formDataObject,
        id,
        $$,
        setData,
        fetch,
        save,
        saveForm,
        update,
        destroy
    } = useBase(type, $data, formFields)

    const date = useDate()

    const roles = computed(() => $data.value.roles || [])
    const permissions = computed(() => $data.value.permissions || [])

    const isActive = computed(() => !!$data.value.activated_at)
    const isEmailVerified = computed(() => !!$data.value.email_verified_at)
    const isTrainee = computed(() => hasRole('trainee'))
    const isSuperAdmin = computed(() => hasRole('super-admin'))
    const isAdmin = computed(() => hasRole('admin'))
    const isProfessional = computed(() => hasRole('professional'))
    const isProfessionalAdmin = computed(() => hasRole('professional-admin'))
    const isProfessionalCertified = computed(() => hasRole('professional-certified'))

    const isCertified = computed(() => !!$data.value.is_certified)

    const meta = (key) => {
        if (undefined === $data.value.metarelation) return undefined

        const metas = $data.value.metarelation
        const obj = metas.find(o => o.key === key);
        if (undefined === obj) return obj

        if (obj.type === 'collection' || obj.type === 'json' || obj.type === 'array') {
            return JSON.parse(obj.value)
        }

        return obj.value
    }

    const updateMeta = (key, value, type) => {
        if (undefined === $data.value.metarelation) return undefined

        const index = $data.value.metarelation.findIndex((o) => o.key === key)
        if (-1 === index) {
            if (!type) {
                switch (typeof value) {
                    case 'object':
                        type = 'collection'
                        value = JSON.stringify(value)
                        break;
                    default:
                        type = typeof value
                }
            }

            $data.value.metarelation.push({
                key,
                value,
                type,
            })
        } else {

            const obj = $data.value.metarelation[index]

            if (obj.type === 'collection' || obj.type === 'json' || obj.type === 'array') {
                $data.value.metarelation[index].value = JSON.stringify(value)
            } else {
                $data.value.metarelation[index].value = value
            }
        }
        return meta(key)
    }

    const organization = computed(() => {
        if (!$data.value.organization) {
            return false
        }
        return useOrganizations().getByData($data.value.organization)
    })

    const avatarUrl = computed(() => {
        const apiStore = useApi()

        if ($data.value.avatar) {
            const hash = window.btoa($data.value.avatar || 'default')
            return apiStore.url(`api/users/${$data.value.id}/avatar/${hash}`)
        }

        return '/img/avatar-user.png'
    })

    const hasRole = (role) => {
        for (let $role of roles.value) {
            if ($role.name === role) {
                return true
            }
        }
    }

    const can = (permission) => {
        if (!hasData.value) {
            return false
        }

        if (hasRole('super-admin')
            && permission !== 'trainee' && permission !== 'professional'
            && !permission.startsWith('trainee-') && !permission.startsWith('professional-')) {

            return true
        }

        // check roles
        for (let $role of roles.value) {
            if ($role.name === permission) {
                return true
            }
            for (let $permission of ($role.permissions || [])) {
                if (permission === $permission.name) return true
            }
        }

        for (let $permission of permissions.value) {
            if (permission === $permission.name) return true
        }

        return false
    }

    const canEdit = computed(() => {
        const authStore = useAuth()

        if (authStore.authUser.id === id.value) {
            return true;
        }

        if (authStore.authUser.hasRole('trainee')) {
            return false
        }

        // edit self organization
        if ($data.value.organization && authStore.authUser.organization.id === $data.value.organization.id) {
            return authStore.authUser.can('edit-users')
        }
        return authStore.authUser.can('edit-others-users')
    })

    const canView = computed(() => {
        const authStore = useAuth()

        if (authStore.authUser.hasRole('trainee')) {
            return false
        }

        // edit self organization
        if (authStore.authUser.organization.id === $data.value.organization.id) {
            return authStore.authUser.can('view-user')
        }

        return authStore.authUser.can('view-others-users')
    })

    const canDestroy = computed(() => {
        const authStore = useAuth()

        if (authStore.authUser.hasRole('trainee')) {
            return false
        }

        if (authStore.authUser.id === id.value) {
            return false
        }

        // edit self organization
        if ($data.value.organization && authStore.authUser.organization.id === $data.value.organization.id) {
            return authStore.authUser.can('delete-users')
        }
        return authStore.authUser.can('delete-others-users')
    })

    const asTrainee = computed(() => {
        return useTrainee($data.value)
    })

    const validateTraineAccessToken = async (token, setErrors, processing) => {

        const apiStore = useApi()

        const formData = new FormData
        formData.append('token', token)

        try {
            const response = await useApi().client().post(`api/users/validate-access-token`, {body: formData}).json()
            return response.data
        } catch (error) {
            return apiStore.processFormErrors(error, setErrors, processing)
        }
    }

    const hasTrainee = (trainee) => {
        const traineeId = typeof trainee === 'object' ? trainee.id : trainee
        if ($data.value.organization
            && $data.value.organization.trainees
            && $data.value.organization.trainees.find(o => o.id === traineeId)) {
            return true
        }

        return false
    }

    const hasUser = (user) => {
        if ($data.value.organization
            && $data.value.organization.users
            && $data.value.organization.users.find(o => o.id === user.id)) {
            return true
        }

        return false
    }

    const isPrivacyAccepted = computed(() => {
        return $data.value.privacy_accepted_at && date($data.value.privacy_accepted_at) > date(useMain().app.privacy_updated)
    })

    const isTosAccepted = computed(() => {
        return $data.value.tos_accepted_at && date($data.value.tos_accepted_at) > date(useMain().app.tos_updated)
    })

    const canNavigate = computed (()=> {
        return isEmailVerified.value && isActive.value && isPrivacyAccepted.value && isTosAccepted.value
    })

    return {
        $data,
        hasData,
        formData,
        formDataObject,
        fetch,
        id,
        $$,
        setData,
        save,
        update,
        saveForm,
        destroy,

        asTrainee,
        hasTrainee,
        hasUser,
        isPrivacyAccepted,
        isTosAccepted,
        canNavigate,

        isActive,
        isEmailVerified,
        isTrainee,
        isSuperAdmin,
        isAdmin,
        isProfessional,
        isProfessionalAdmin,
        isProfessionalCertified,
        isCertified,
        roles,
        permissions,
        meta,
        updateMeta,
        avatarUrl,
        organization,
        hasRole,
        can,
        canEdit,
        canView,
        canDestroy,

        validateTraineAccessToken,
    }
}
