import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime"; //if using
import duration from "dayjs/plugin/duration"; //if using
import plugin from "@/includes/dayjs/plugin"
import "dayjs/locale/fr"

export function useDate() {
    dayjs.extend(relativeTime)
    dayjs.extend(duration)

    dayjs.extend(plugin)

    dayjs.locale('fr')
    return dayjs
}
