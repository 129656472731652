<script setup>

import {computed} from "vue";
import {useRouter} from "vue-router";
import Navigation from "@/components/Navigation.vue";

const $router = useRouter();

const items = {
    about: {
        label: 'À propos',
        icon: 'mdi mdi-information-outline',
        to: {name: 'about'},
    },
    register: {
        label: "S'enregistrer",
        to: {name: 'register'},
        icon: 'mdi mdi-file-document-edit-outline',
    },
    login: {
        label: "Se connecter",
        to: {name: 'login'},
        icon: 'mdi mdi-login',
    }
}

const aboutItems = {
    login: {
        label: 'Se connecter',
        icon: 'mdi mdi-login',
        to: {name: 'login'},
        back: true,
    },
    about: {
        label: 'À propos',
        icon: 'mdi mdi-information-outline',
        to: {name: 'about'},
    },
    privacy: {
        label: 'Politique de confidentialité',
        icon: 'mdi mdi-shield-account-outline',
        to: {name: 'privacy'},
    },
    terms: {
        label: "Conditions d'utilisation",
        icon: 'mdi mdi-file-sign',
        to: {name: 'terms'},
    },

}

const menuItems = computed(() => {
    if ($router.currentRoute.value.matched[0] && ['about', 'privacy','terms'].includes($router.currentRoute.value.matched[0].name)) {
        return aboutItems
    }

    return items
})

</script>

<template>
    <Navigation :items="menuItems"></Navigation>
</template>
