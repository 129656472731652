const About = () => import(/* webpackChunkName: "public" */ '@/pages/About.vue')
const TermsOfUse = () => import(/* webpackChunkName: "public" */ '@/pages/TermsOfUse.vue')
const PrivacyPolicy = () => import(/* webpackChunkName: "public" */ '@/pages/PrivacyPolicy.vue')
export default [
    {
        path: '/a-propos',
        component: About,
        name: 'about',
        meta: {
            title: "À propos",
            guard: 'guest',
        },
    },
    {
        path: '/politique-de-confidentialite',
        component: PrivacyPolicy,
        name: 'privacy',
        meta: {
            title: "Politique de confidentialité",
            guard: 'guest',
        },
    },
    {
        path: '/conditions-d-utilisation',
        component: TermsOfUse,
        name: 'terms',
        meta: {
            title: "Conditions d'utilisation",
            guard: 'guest',
        },
    },
]
