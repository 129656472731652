<script setup>
import OrganizationCard from "@/components/Organization/Card.vue";
import {inject, ref} from "vue";

const dialogRef = inject('dialogRef')

const props = defineProps({
    item: {
        type: Object,
    }
})

const $item = ref(dialogRef.value.data.item || props.item || null)

</script>
<template>
    <OrganizationCard :item="$item" v-if="$item"/>
    <div v-else>
        Organization non spécifiée
    </div>
</template>
